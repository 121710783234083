import { useEffect } from 'react';

import { css } from '@emotion/core';

import { breakpoints } from '@soomo/lib/styles/themes';

import { WebtextSignInContext } from './context';
import PageWrapper from './PageWrapper';

interface Props {
	marketingHost: string;
	clientId: string;
	loginUri: string;
	errorMessage: string | null;
}

const GoogleSignin: React.FC<Props> = ({ marketingHost, clientId, loginUri, errorMessage }) => {
	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		document.body.appendChild(script);
	}, []);

	return (
		<WebtextSignInContext.Provider value={{ marketingHost }}>
			<PageWrapper pageHeading="Please confirm your identity" title="Sign in with Google | Soomo">
				<div css={styles}>
					{errorMessage ? <p className="error">{errorMessage}</p> : null}
					<div
						id="g_id_onload"
						data-client_id={clientId}
						data-context="signin"
						data-ux_mode="popup"
						data-login_uri={loginUri}
						data-auto_prompt="false"
					/>
					<div
						className="g_id_signin"
						data-type="standard"
						data-shape="pill"
						data-theme="outline"
						data-text="signin_with"
						data-size="large"
						data-width="300"
						data-logo_alignment="center"
					/>
				</div>
			</PageWrapper>
		</WebtextSignInContext.Provider>
	);
};

export default GoogleSignin;

const styles = css`
	width: 100%;

	.g_id_signin {
		margin: 20px auto;
		width: 300px;
	}

	.error {
		font-size: 22px;
		text-align: center;
		max-width: 80%;
		margin: 17px auto;
	}

	@media (max-width: ${breakpoints.small}) {
		.error {
			font-size: 18px;
		}
	}
`;
