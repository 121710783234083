import { css } from '@emotion/core';

import { breakpoints } from '@soomo/lib/styles/themes';

import { WebtextSignInContext } from './context';
import PageWrapper from './PageWrapper';

interface Props {
	marketingHost: string;
	message: string;
	continueUrl: string;
}

const LtiLaunchMessage: React.FC<Props> = ({ marketingHost, message, continueUrl }) => {
	return (
		<WebtextSignInContext.Provider value={{ marketingHost }}>
			<PageWrapper pageHeading="" title="Continue | Soomo">
				<div css={styles}>
					<p className="message" dangerouslySetInnerHTML={{ __html: message }} />
					<p className="message" style={{ textAlign: 'right', marginTop: '2em' }}>
						<a href={continueUrl}>Continue &rarr;</a>
					</p>
				</div>
			</PageWrapper>
		</WebtextSignInContext.Provider>
	);
};

export default LtiLaunchMessage;

const styles = css`
	width: 100%;

	.message {
		font-size: 22px;
		text-align: center;
		max-width: 80%;
		margin: 17px auto;
	}

	@media (max-width: ${breakpoints.small}) {
		.message {
			font-size: 18px;
		}
	}
`;
