import { useState } from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import { axiosConfig } from '~/api/axiosConfig';
import { logError } from '~/utils/rollbar';

import Alert from './Alert';
import Button from './Button';
import { routerPath } from './constants';
import { formStyles, linkStyles, messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';

axiosConfig();

const StudentSignIn: React.FC = () => {
	const [formData, setFormData] = useState({
		email: '',
		password: ''
	});

	const [error, setError] = useState(null);

	const handleFormSubmit = async (e) => {
		e.preventDefault();

		const payload = {
			email_address: formData.email,
			password: formData.password
		};

		try {
			const response = await axios.post('/user/signin', payload, {
				headers: {
					'Content-Type': 'application/json'
				}
			});
			window.location.href = response.data.redirectPath;
		} catch (error) {
			setError(error);
			logError(error);
			setFormData({ ...formData, password: '' });
		}
	};

	return (
		<PageWrapper pageHeading="Student sign-in" title="Student Sign-in | Soomo">
			{error && (
				<div css={messageStyles}>
					<Alert severity="error">
						Your email or password did not work. If you need to reset your password, click the
						forgot password link.
					</Alert>
				</div>
			)}
			<div css={formStyles}>
				<form onSubmit={handleFormSubmit}>
					<label htmlFor="email">Email address</label>
					<input
						value={formData.email}
						id="email"
						placeholder="Email Address"
						type="email"
						autoComplete="username"
						required
						onChange={(e) => setFormData({ ...formData, email: e.target.value })}
					/>

					<label htmlFor="password">Password</label>
					<input
						value={formData.password}
						id="password"
						placeholder="Password"
						type="password"
						autoComplete="current-password"
						required
						onChange={(e) => setFormData({ ...formData, password: e.target.value })}
					/>

					<div className="form-footer">
						<div className="links-block">
							<a
								href="/signup/student?from=signin"
								target="_blank"
								rel="noreferrer"
								title="New student sign-up"
								css={linkStyles}>
								New student sign-up
							</a>
							<Link to={routerPath.forgotPassword} css={linkStyles}>
								Forgot password
							</Link>
						</div>

						<Button type="submit">Sign in</Button>
					</div>
				</form>
			</div>
		</PageWrapper>
	);
};

export default StudentSignIn;
