import { css } from '@emotion/core';

import { breakpoints } from '@soomo/lib/styles/themes';

import { WebtextSignInContext } from './context';
import PageWrapper from './PageWrapper';

interface Props {
	marketingHost: string;
	title: string;
	message: string;
	showSupport?: boolean;
}

const SigninMessage: React.FC<Props> = ({ marketingHost, title, message, showSupport }) => {
	return (
		<WebtextSignInContext.Provider value={{ marketingHost }}>
			<PageWrapper pageHeading={title} title={`${title} | Soomo`}>
				<div css={styles}>
					<p className="message" dangerouslySetInnerHTML={{ __html: message }} />
					{showSupport ? (
						<p className="message">
							Please contact Soomo <a href="/support">Support</a> if you’re not certain how to
							resolve the error.
						</p>
					) : null}
				</div>
			</PageWrapper>
		</WebtextSignInContext.Provider>
	);
};

export default SigninMessage;

const styles = css`
	width: 100%;

	.message {
		font-size: 22px;
		margin-top: 17px;
		text-align: center;
	}

	@media (max-width: ${breakpoints.small}) {
		.message {
			font-size: 18px;
		}
	}
`;
