import { messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';

const WGUSupportPage: React.FC = () => {
	return (
		<PageWrapper pageHeading="Hello WGU student" title="WGU Password Reset | Soomo">
			<div css={messageStyles}>
				Sorry for the confusion with your password. To access our site, sign in at{' '}
				<a href="//my.wgu.edu/">my.wgu.edu</a> and click the links in the course of study.
				<br />
				If you are having trouble with your password or need to have it sent to you, please use the
				my.wgu.edu tools.
			</div>
		</PageWrapper>
	);
};

export default WGUSupportPage;
