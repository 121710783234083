import { Link } from 'react-router-dom';

import { css } from '@emotion/core';

import { supportPhone, supportEmail } from '@soomo/lib/properties/support';

import { messageStyles, linkStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';

interface Props {
	email: string;
	backLink: string;
}

const ResetEmailSent: React.VFC<Props> = ({ email, backLink }) => {
	return (
		<PageWrapper pageHeading="Email sent" title="Password Reset Email Sent | Soomo">
			<div css={messageStyles}>
				We’ve sent an email to {email} with a link and instructions to reset your password. If you
				do not receive this email, please contact Support:
				<br />
				{supportPhone} or {supportEmail}
			</div>
			<Link to={backLink} css={[linkStyles, submittedBackToSigninLinkStyles]}>
				Back to sign-in
			</Link>
		</PageWrapper>
	);
};

export default ResetEmailSent;

const submittedBackToSigninLinkStyles = css`
	margin-top: 4rem;
`;
