import { WebtextSignInContext } from './context';
import { messageStyles } from './InstructorSignIn';
import PageWrapper from './PageWrapper';

interface Props {
	currentUserPresent: boolean;
	sessionExpired: boolean;
	signinUrl: string;
	signoutUrl: string;
	mixedRegistration: boolean;
	marketingHost: string;
}

const SessionExpiredPage: React.FC<Props> = (props) => {
	const {
		currentUserPresent,
		sessionExpired,
		signinUrl,
		signoutUrl,
		mixedRegistration,
		marketingHost
	} = props;

	const pageHeading =
		currentUserPresent && sessionExpired ? 'We signed you out.' : 'You are not signed in.';

	return (
		<WebtextSignInContext.Provider value={{ marketingHost }}>
			<PageWrapper pageHeading={pageHeading} title="Signed Out | Soomo">
				{currentUserPresent && sessionExpired && (
					<div css={messageStyles}>
						Because you didn’t use your webtext for a few hours, we signed you out to protect your
						privacy.
					</div>
				)}

				{currentUserPresent && (
					<>
						<div css={messageStyles}>
							It looks like you {mixedRegistration ? 'may ' : ' '}access Soomo through your school’s
							learning management system. Please return there and click the link you normally use to
							access your webtext.
						</div>

						{signoutUrl && (
							<div css={messageStyles}>
								For your convenience, this link will take you to your school’s sign-in page:
								<a href={signoutUrl} target="_blank" rel="noreferrer">
									{signoutUrl}
								</a>
							</div>
						)}

						{mixedRegistration && (
							<div css={messageStyles}>
								It also looks like you may sometimes access Soomo directly. Click{' '}
								<a href={signinUrl} target="_blank" rel="noreferrer">
									here
								</a>{' '}
								to sign in with your email address and password.
							</div>
						)}
					</>
				)}

				{!currentUserPresent && (
					<>
						<div css={messageStyles}>
							Most users access Soomo webtexts through their school’s learning management system. If
							you typically access your webtext in that way, go back to your LMS and click the link
							that you normally use there.
						</div>
						<div css={messageStyles}>
							Some users access webtexts through a direct sign-in at Soomo, using an email address
							and a password. If that is how you typically access your webtext,{' '}
							<a href={signinUrl} target="_blank" rel="noreferrer">
								visit the sign in page
							</a>{' '}
							and do so.
						</div>
					</>
				)}

				<div css={messageStyles}>
					If you’re unsure how to proceed, please get in touch with our{' '}
					<a
						href={`https://${marketingHost}/support`}
						target="_blank"
						rel="noreferrer"
						title="Soomo Learning Support">
						support department
					</a>
					.
				</div>
			</PageWrapper>
		</WebtextSignInContext.Provider>
	);
};

export default SessionExpiredPage;
